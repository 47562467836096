import React from 'react'
import {TabsAlignment, TabsSkin, TabsVariant} from 'wix-ui-tpa/cssVars'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Tabs} from '../../../../../commons/enums'
import {Tabs as TabsComp} from '../styled-components/tabs'
import settingsParams from '../../../settingsParams'
import {useSettings} from '../../../hooks/settings'
import {EventTabsProps} from '.'

export const EventsTabs = ({
  tab,
  setTab,
  mobile,
  rtl,
  pastEventsTabLabel,
  upcomingEventsTabLabel,
  closeAllEvents,
}: EventTabsProps) => {
  const settings = useSettings()

  const onTabClick = (newTab: Tabs) => {
    setTab(newTab)
    closeAllEvents()
  }

  return (
    <TabsComp
      data-hook={DH.EVENTS_MA_PAGE_EVENT_LIST_TABS}
      items={[
        {title: settings.get(settingsParams.upcomingEventsTab) ?? upcomingEventsTabLabel},
        {title: settings.get(settingsParams.pastEventsTab) ?? pastEventsTabLabel},
      ]}
      onTabClick={onTabClick}
      alignment={mobile ? TabsAlignment.center : rtl ? TabsAlignment.right : TabsAlignment.left}
      variant={mobile ? TabsVariant.fullWidth : TabsVariant.fit}
      skin={mobile ? TabsSkin.fullUnderline : TabsSkin.clear}
      activeTabIndex={tab}
      mobile={mobile}
    />
  )
}
